import store from 'shared/store';
import { createApp } from 'vue';
import CheckoutModalLogin from 'multisite/components/checkout/checkoutModalLogin.vue';

const campaignLandingPagePoint = document.querySelector('[data-feature="campaignLandingPage"]');

if (campaignLandingPagePoint) {
	const app = createApp({
		name: 'campaignLandingPageApp',
	});
	app.use(store);
	app.component('checkout-modal-login', CheckoutModalLogin);
	app.mount(campaignLandingPagePoint);
}